@import '../../../../cl_base/scss/theme-bootstrap';

.carousel-slider-container {
  position: relative;
  padding: 0 47px;
  @include breakpoint ($cr19-small) {
    padding: 0;
  }
  .carousel-slider-products-container {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    .carousel-slider-products {
      position: relative;
      width: max-content;
      margin: 0;
      padding: 0;
      left: 0;
      .product-wrapper {
        @include breakpoint ($cr19-small) {
          width: 100vw;
          padding: 0 50px;
        }
      }
    }
  }
  .carousel-slider-arrow {
    position: absolute;
    display: block;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: $color-black;
    height: 100%;
    z-index: 1;
    padding: 0 15px;
    &.left {
      left: 0;
      svg {
        transform: rotate(180deg);
      }
    }
    &.right {
      right: 0;
    }
    svg {
      width: 20px;
      height: 100%;
    }
    &:focus {
      outline: 0 !important;
    }
    @include breakpoint($cr19-small) {
      &.left {
        padding: 200px 10px 200px 20px;
      }
      &.right {
        padding: 200px 20px 200px 10px;
      }
    }
  }
}

.products-wrapper {
  padding: 0 47px;
  @include breakpoint($cr19-small) {
    padding: 0 25px;
  }
}

.product-learn-more-wrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden auto;
  text-align: center;
  background: $color-black-54opacity;
  z-index: 10000;
  .product-learn-more-mask {
    display: flex;
    position: fixed;
    overflow: hidden;
    flex-direction: column;
    inset: 0;
    background-color: $color-black-4opacity;
    -webkit-box-pack: end;
    justify-content: flex-end;
    z-index: 100005;
  }
  .product-learn-more-popup {
    position: absolute;
    width: 100%;
    max-width: 1100px;
    height: auto;
    top: 22%;
    left: 50%;
    padding: 0;
    transform: translateX(-50%) translateY(-20%);
    background-color: $color-white;
    @include breakpoint ($cr19-xlarge) {
      max-width: 870px;
    }
    .product-learn-more-popup-close-button {
      position: absolute;
      right: 0;
      margin: 10px;
      background-color: $color-white;
      border: none;
      outline: none;
      cursor: pointer;
      .product-learn-more-popup-close-button-icon {
        width: 20px;
        height: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .product-learn-more-popup-left {
      display: inline-block;
      width: 50%;
      padding: 50px 10px 50px 64px;
      vertical-align: top;
      .product-learn-more-popup-left-img-wrapper {
        min-height: 595px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-light;
        @include breakpoint ($cr19-xlarge) {
          min-height: 300px;
        }
        .product-learn-more-popup-left-img {
          width: 100%;
        }
      }
    }
    .product-learn-more-popup-right {
      display: inline-block;
      width: 50%;
      padding: 50px 64px 0 24px;
      vertical-align: top;
    }
    .product-learn-more-popup-right-name {
      h2 {
        margin: 0;
        text-align: left;
        line-height: 1.14286;
        font-size: 28px;
        font-family: $base-bolder-font-family;
        letter-spacing: 0.02em;
        color: $color-black;
        @include breakpoint ($cr19-xlarge) {
          font-size: 24px;
        }
      }
    }
    .product-learn-more-popup-right-desc {
      padding-inline-end: 20px;
      max-height: 300px;
      margin-top: 10px;
      overflow: auto;
      text-align: left;
      scrollbar-width: auto;
      scrollbar-color: $color-black $color-grey;
      @include breakpoint ($cr19-xlarge) {
        max-height: 195px;
      }
      p {
        display: inline;
        line-height: 1.41176;
        font-size: 17px;
        font-family: $base-font-family-roman;
        letter-spacing: 0.02em;
        color: $color-black;
        @include breakpoint ($cr19-xlarge) {
          font-size: 13px;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: $color-grey;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-black;
        border: 0 none;
        border-radius: 5px;
      }
    }
    .product-shade-picker-dropdown {
      display: none;
      width: 50%;
      margin: 32px 0;
      text-align: left;
      font-family: $base-bolder-font-family;
      .product-shade-picker-dropdown-wrapper {
        display: flex;
        position: relative;
        padding-left: 5px;
        -webkit-box-align: center;
        align-items: center;
        border: 1px solid $color-gray-tier2;
        border-radius: 2px;
        cursor: pointer;
      }
      .product-shade-picker-dropdown-shade-hex {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 0 8px;
        .product-shade-picker-dropdown-shade-hex-icon {
          width: 16px;
          height: 16px;
        }
      }
      .product-shade-picker-dropdown-shade-name {
        display: flex;
        padding: 0 8px;
        flex: 1 1 0%;
        -webkit-box-align: center;
        align-items: center;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .product-shade-picker-dropdown-shade-arrow {
        height: 40px;
        padding: 0 16px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        .product-shade-picker-dropdown-shade-arrow-icon {
          width: 10px;
          height: 10px;
          cursor: pointer;
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
      .product-shade-picker-dropdown-shade-arrow.open {
        transform: rotate(180deg);
      }
      .product-shade-picker-dropdown-options-list {
        display: none;
        position: relative;
        max-height: 151px;
        overflow-y: auto;
        border: 1px solid $color-gray-tier2;
        list-style: none;
        background: $color-white;
        z-index: 2;
        @include breakpoint ($cr19-xlarge) {
          max-height: 122px;
        }
        .product-shade-picker-dropdown-option {
          display: flex;
          padding: 10px 5px;
          border-top: 0.01em solid $color-grey;
          justify-content: space-between;
          text-align: right;
          font-weight: normal;
          background-color: initial;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
    .product-learn-more-popup-right-buttons {
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0 64px 50px 0;
      .product-learn-more-popup-right-shop-button {
        display: none;
        margin-right: 5px;
      }
      .product-learn-more-popup-right-store-button {
        margin-left: 5px;
      }
      button {
        width: auto;
        min-width: 220px;
        height: 64px;
        padding: 0 30px;
        line-height: unset;
        font-size: 17px;
        font-family: $base-font-family-roman;
        font-weight: 700;
        text-align: center;
        color: $color-white;
        background-color: $color-black;
        cursor: pointer;
      }
    }
  }
}

html[lang='ar-ME'] {
  .product-learn-more-wrapper {
    .product-learn-more-popup {
      .product-learn-more-popup-right-buttons {
        right: auto;
        left: 0;
        margin: 0 0 50px 64px;
      }
      .product-learn-more-popup-right-desc {
        text-align: right;
      }
      .product-learn-more-popup-right {
        padding: 50px 24px 0 64px;
      }
      .product-shade-picker-dropdown {
        .product-shade-picker-dropdown-wrapper {
          padding-right: 5px;
          padding-left: 0;
        }
        .product-shade-picker-dropdown-shade-arrow {
          .product-shade-picker-dropdown-shade-arrow-icon {
            svg {
              vertical-align: top;
            }
          }
        }
      }
      .product-learn-more-popup-left {
        padding: 50px 64px 50px 10px;
      }
      .product-learn-more-popup-close-button {
        left: 0;
        right: auto;
      }
      .product-learn-more-popup-right-name {
        h2 {
          text-align: right;
        }
      }
    }
  }
}
